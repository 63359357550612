import ExamplesDialog from "@code/authzed-common/src/components/ExamplesDialog";
import { Example } from "@code/authzed-common/src/examples";
import {
  ObjectOrCaveatDefinition,
  ParsedSchema,
  parseSchema,
} from "@code/authzed-common/src/parsers/dsl/dsl";
import { rewriteSchema } from "@code/authzed-common/src/parsers/dsl/generator";
import {
  convertRelationshipsToStrings,
  parseRelationships,
} from "@code/authzed-common/src/parsing";
import { DeveloperServiceClient } from "@code/authzed-common/src/protodefs/authzed/api/v0/DeveloperServiceClientPb";
import {
  LookupShareRequest,
  LookupShareResponse,
} from "@code/authzed-common/src/protodefs/authzed/api/v0/developer_pb";
import { RelationTuple as Relationship } from "@code/authzed-common/src/protodevdefs/core/v1/core";
import {
  PopulateTenantParameters,
  POPULATE_TENANT,
} from "@code/authzed-common/src/queries/tenant";
import {
  TenantPermission,
  WithoutTenantPermission,
  WithTenantPermission,
} from "@code/authzed-common/src/services/permissionsservice";
import { UserEvent } from "@code/authzed-common/src/types/events";
import { Tenant } from "@code/authzed-common/src/types/tenant";
import {
  ParsedValidation,
  parseValidationYAML,
} from "@code/authzed-common/src/validationfileformat";
import { AmplitudeContext } from "@code/trumpet/src/AmplitudeClient";
import { useManagedMutation } from "@code/trumpet/src/hooks";
import { CircularProgress, TextField } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import InputIcon from "@material-ui/icons/Input";
import PublishIcon from "@material-ui/icons/Publish";
import ShareIcon from "@material-ui/icons/Share";
import Alert from "@material-ui/lab/Alert";
import clsx from "clsx";
import * as grpcWeb from "grpc-web";
import React, { useContext, useState } from "react";
import AppConfig from "../services/configservice";
import { FileSelector } from "./FileSelector";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    paper: {},
    populates: {
      width: "100%",
      textAlign: "center",
      marginTop: theme.spacing(1),
    },
    populateOptions: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      columnGap: theme.spacing(1),
    },
    populateSelector: {
      padding: theme.spacing(1),
      display: "inline-block",
      border: "2px solid transparent",
      cursor: "pointer",
      borderRadius: "4px",

      backgroundColor: theme.palette.background.default,
      borderColor: theme.palette.background.paper,
    },
    populateIcon: {
      "& svg": {
        fontSize: "425%",
      },
    },
    populateTitle: {},
    populateDescription: {
      color: theme.palette.grey[500],
      marginTop: theme.spacing(1),
      textTransform: "none",
    },
    populateSummary: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.default,
    },
    populating: {
      padding: theme.spacing(1),
      display: "grid",
      justifyContent: "center",
      alignItems: "center",
      gridTemplateColumns: "auto auto",
      columnGap: theme.spacing(2),
    },
    populatingProgress: {
      color: theme.palette.text.primary,
    },
    throbber: {
      color: theme.palette.text.primary,
    },
  })
);

interface PopulationMethod {
  title: string;
  description: string;
  image: React.ReactNode;
  content: (props: PopulationContentProps) => JSX.Element;
}

interface ValidationContent {
  validationFile: ParsedValidation | undefined;
  parsedSchema: ParsedSchema | undefined;
  parsedRelationships: Relationship[] | undefined;
}

interface UploadFileState {
  filename: string;
  content: ValidationContent | undefined;
}

interface ShareLoadState {
  loading: boolean;
  shareId: string;
  content: ValidationContent | undefined;
}

interface PopulationContentProps {
  tenant: Tenant;
  startPopulate: (data: ParsedValidation) => void;
}

function ValidationContentDisplay(props: {
  title: string;
  content: ValidationContent | undefined;
}) {
  const classes = useStyles();
  const content = props.content;
  return (
    <div>
      {content?.parsedSchema === undefined && (
        <Alert severity="error">
          {props.title} does not contain valid data
        </Alert>
      )}
      {content?.parsedSchema !== undefined &&
        content.parsedSchema?.definitions?.length === 0 && (
          <Alert severity="warning">{props.title} is empty</Alert>
        )}
      {content?.parsedSchema !== undefined &&
        content.parsedSchema.definitions?.length > 0 && (
          <Paper className={classes.populateSummary}>
            <Typography variant="caption" color="textSecondary">
              Definitions to be populated
            </Typography>
            <Typography variant="subtitle1">
              {content.parsedSchema.definitions
                .map((def: ObjectOrCaveatDefinition) => def.name)
                .join(", ")}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              Relationships to be populated
            </Typography>
            <Typography variant="subtitle2">
              {content?.parsedRelationships?.length}
            </Typography>
          </Paper>
        )}
    </div>
  );
}

const METHODS: PopulationMethod[] = [
  {
    title: "Quick Start from example",
    description: "Populate the permissions system from an example",
    image: <InputIcon />,
    content: function QuickStart(props: PopulationContentProps) {
      const amplitudeClient = useContext(AmplitudeContext);
      const loadExampleData = (ex: Example) => {
        amplitudeClient?.logEvent(UserEvent.ClickQuickStart);
        props.startPopulate(ex.data);
      };

      return <ExamplesDialog offset={56} loadExampleData={loadExampleData} />;
    },
  },
  {
    title: "Load Shared Playground",
    description:
      "Populate the permissions system with the definitions and relationships found in a shared playground link",
    image: <ShareIcon />,
    content: function LoadShared(props: PopulationContentProps) {
      const amplitudeClient = useContext(AmplitudeContext);
      const urlRegex = /\/s\/([^/]+)(.*)$/;
      const [open, setOpen] = useState(true);
      const [shareLoadState, setShareLoadState] = useState<
        ShareLoadState | undefined
      >(undefined);
      const [shareUrl, setShareUrl] = useState("");

      const classes = useStyles();

      const loadShared = (shareReference: string) => {
        setShareLoadState({
          loading: true,
          shareId: shareReference,
          content: undefined,
        });

        const service = new DeveloperServiceClient(
          AppConfig().authzed.gatewayEndpoint,
          null,
          null
        );

        const request = new LookupShareRequest();
        request.setShareReference(shareReference);

        service.lookupShared(
          request,
          {},
          (err: grpcWeb.RpcError, response: LookupShareResponse) => {
            if (
              err ||
              response.getStatus() ===
                LookupShareResponse.LookupStatus.FAILED_TO_LOOKUP
            ) {
              setShareLoadState({
                loading: false,
                shareId: shareReference,
                content: undefined,
              });
              return;
            }

            setShareLoadState({
              loading: false,
              shareId: shareReference,
              content: {
                validationFile: {
                  schema: response.getSchema(),
                  relationships: response.getRelationshipsYaml(),
                  validation: {},
                },
                parsedSchema: parseSchema(response.getSchema()),
                parsedRelationships: parseRelationships(
                  response.getRelationshipsYaml()
                ),
              },
            });
          }
        );
      };

      const handleChangeUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShareUrl(event.target.value);

        if (
          !event.target.value.startsWith(AppConfig().authzed.playgroundEndpoint)
        ) {
          setShareLoadState(undefined);
          return;
        }

        const parsed = urlRegex.exec(event.target.value);
        if (parsed && parsed[1]) {
          if (shareLoadState?.shareId !== parsed[1]) {
            loadShared(parsed[1]);
            return;
          }
        } else {
          setShareLoadState(undefined);
        }
      };

      const handlePopulate = () => {
        if (shareLoadState?.content?.validationFile) {
          props.startPopulate(shareLoadState.content.validationFile);
          setOpen(false);
        }
        amplitudeClient?.logEvent(UserEvent.ClickLoadPlayground);
      };

      const isValidUrl = shareUrl.length === 0 || !!urlRegex.exec(shareUrl);

      return (
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>Load Shared Playground</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the URL of a shared Playground to load its object type
              definitions and test relationships into the permissions system.
            </DialogContentText>
            <TextField
              error={!isValidUrl}
              fullWidth
              helperText={!isValidUrl ? "Please enter a valid share URL" : ""}
              label="Shared Playground URL"
              placeholder={`${
                AppConfig().authzed.playgroundEndpoint
              }/s/{shareId}/...`}
              value={shareUrl}
              InputProps={{
                endAdornment: (
                  <span>
                    {shareLoadState?.loading && (
                      <CircularProgress
                        className={classes.throbber}
                        size="1em"
                      />
                    )}
                  </span>
                ),
              }}
              onChange={handleChangeUrl}
            />
            {shareLoadState !== undefined && !shareLoadState.loading && (
              <ValidationContentDisplay
                content={shareLoadState?.content}
                title="The entered playground share URL"
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="default">
              Cancel
            </Button>
            <Button
              onClick={handlePopulate}
              disabled={shareLoadState?.content?.parsedSchema === undefined}
              color="secondary"
              variant="contained"
            >
              Populate Permissions System from shared Playground
            </Button>
          </DialogActions>
        </Dialog>
      );
    },
  },
  {
    title: "Upload YAML Bundle",
    description:
      "Populate the permissions system with the object type definitions and relationships found in an uploaded config YAML",
    image: <PublishIcon />,
    content: function UploadFile(props: PopulationContentProps) {
      const amplitudeClient = useContext(AmplitudeContext);
      const [open, setOpen] = useState(true);
      const [uploadFileState, setUploadFileState] = useState<
        UploadFileState | undefined
      >(undefined);

      const handleFileDropped = (file: File | null) => {
        (async () => {
          if (file) {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = function (e) {
              const contents = reader.result as string;
              const parsed = parseValidationYAML(contents);
              if ("message" in parsed) {
                return;
              }

              setUploadFileState({
                filename: file.name,
                content: parsed
                  ? {
                      validationFile: parsed,
                      parsedSchema: parseSchema(parsed.schema),
                      parsedRelationships: parseRelationships(
                        parsed.relationships
                      ),
                    }
                  : undefined,
              });
            };
          } else {
            setUploadFileState(undefined);
          }
        })();
      };

      const handlePopulate = () => {
        setOpen(false);
        props.startPopulate(uploadFileState?.content?.validationFile!);
        amplitudeClient?.logEvent(UserEvent.ClickUploadBundle);
      };

      return (
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>Upload Namspace Bundle</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Select the YAML bundle file to upload. The selected file must have
              been produced via "Save" from within the Playground.
            </DialogContentText>
            <FileSelector
              title="Select YAML Bundle"
              accept=".yaml"
              onSingleFileChange={handleFileDropped}
            />
            {uploadFileState !== undefined && (
              <ValidationContentDisplay
                content={uploadFileState?.content}
                title="The uploaded data file"
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="default">
              Cancel
            </Button>
            <Button
              onClick={handlePopulate}
              disabled={uploadFileState === undefined}
              color="secondary"
              variant="contained"
            >
              Populate Tenant{" "}
              {uploadFileState !== undefined
                ? `from ${
                    uploadFileState.filename.length > 10
                      ? "file"
                      : uploadFileState.filename
                  }`
                : ""}
            </Button>
          </DialogActions>
        </Dialog>
      );
    },
  },
];

export default function PopulateTenantPanel(props: {
  tenant: Tenant;
  disabled: boolean;
}) {
  const classes = useStyles();
  const [currentPopulate, setCurrentPopulate] = useState<
    PopulationMethod | undefined
  >(undefined);
  const [currentPopulateIndex, setCurrentPopulateIndex] = useState(0);
  const [populateTenant, { running: populating }] = useManagedMutation<
    any,
    PopulateTenantParameters
  >(POPULATE_TENANT);

  const showPopulate = (def: PopulationMethod) => {
    if (populating) {
      return;
    }

    setCurrentPopulateIndex(currentPopulateIndex + 1);
    setCurrentPopulate(def);
  };

  const handleStartPopulate = (content: ParsedValidation) => {
    (async () => {
      await populateTenant({
        variables: {
          tenantId: props.tenant.id,
          schema: rewriteSchema(content.schema, props.tenant.slug) ?? "",
          relationships: convertRelationshipsToStrings(
            parseRelationships(content.relationships)
          ),
        },
      });
    })();
  };

  const PopulateContent =
    currentPopulate !== undefined
      ? currentPopulate.content
      : () => {
          return <div />;
        };

  return (
    <Box className={classes.paper}>
      <WithTenantPermission
        tenant={props.tenant}
        permission={TenantPermission.PopulateTenant}
      >
        {!populating && (
          <div className={classes.populates}>
            <div className={classes.populateOptions}>
              {METHODS.map((def: PopulationMethod, index: number) => {
                return (
                  <Button
                    disabled={populating || props.disabled}
                    key={index}
                    className={clsx(classes.populateSelector)}
                    onClick={() => showPopulate(def)}
                  >
                    <div className={classes.populateIcon}>{def.image}</div>
                    <div className={classes.populateTitle}>{def.title}</div>
                    <Typography
                      variant="subtitle2"
                      className={classes.populateDescription}
                    >
                      {def.description}
                    </Typography>
                  </Button>
                );
              })}
            </div>
          </div>
        )}
        {populating && (
          <Box className={classes.populating}>
            <CircularProgress className={classes.populatingProgress} />
            <Typography variant="h5">
              Populating Permissions System...
            </Typography>
          </Box>
        )}
        <PopulateContent
          key={currentPopulateIndex}
          tenant={props.tenant}
          startPopulate={handleStartPopulate}
        />
      </WithTenantPermission>
      <WithoutTenantPermission
        tenant={props.tenant}
        permission={TenantPermission.PopulateTenant}
      >
        <Alert severity="warning">
          You do not have permission to update this permissions system. Please
          contact an administrator.
        </Alert>
      </WithoutTenantPermission>
    </Box>
  );
}
