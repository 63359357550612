import {
  AllowedAuthenticationTypes,
  Auth0Config,
  OIDCConfig,
} from '@code/authzed-common/src/authn/provider';
import { env } from 'process';
import * as config from '../config.json';

/**
 * Configuration that we get through an environment js file.
 */
interface EnvConfig {
  AUTH0_CLIENT_ID?: string;
  AUTH0_CONNECTION_NAME?: string;
  OIDC_URL_PREFIX?: string;
  AUTHENTICATION_ENGINE?: string;

  AUTHZED_BACKEND_ENDPOINT?: string;
  AUTHZED_GATEWAY_ENDPOINT?: string;
  AUTHZED_PLAYGROUND_ENDPOINT?: string;
  AUTHZED_PROMETHEUS_ENDPOINT?: string;
  AUTHZED_API_ENDPOINT?: string;
  AUTHZED_RELAY_ENDPOINT?: string;
  AUTHZED_INACTIVE_DEVELOPMENT_TENANT_EXPIRATION?: string;
  STRIPE_PUBLISHABLE_KEY?: string;
  GOOGLE_ANALYTICS_MEASUREMENT_ID?: string;
  STATUSPAGE_PAGE_ID?: string;
  AMPLITUDE_API_KEY?: string;
  STATUSPAGE_COMPONENT_COMMA_SEP_IDS?: string;
  SENTRY_DSN?: string;
  CALENDLY_URL?: string;
  FEATURE_BILLING?: string;
  DISCORD_INVITE_URL?: string;
  SEGMENT_WRITE_KEY?: string;
}

declare global {
  interface Window {
    _env_?: EnvConfig;
  }
}

/**
 * StripeConfig is the configuration for Stripe.
 */
interface StripeConfig {
  /**
   * featureBilling is whether billing is enabled.
   */
  featureBilling: boolean;

  /**
   * stripeKey is the publishable key for Stripe.
   */
  stripeKey: string;
}

/**
 * GoogleAnalyticsConfig is the configuration for Google Analytics.
 */
interface GoogleAnalyticsConfig {
  /**
   * measurementId is the GA monitoring ID for the app.
   */
  measurementId: string;
}

/**
 * StatusPageConfig is the configuration for statuspage.io.
 */
interface StatusPageConfig {
  /**
   * pageId is the ID of the statuspage page.
   */
  pageId: string;

  /**
   * componentIds are the component IDs to display. All others will be filtered.
   */
  componentIds?: string[] | undefined | null;
}

interface AuthZedConfig {
  /**
   * endpoint is the endpoint for the Authzed UI backend.
   */
  endpoint: string;

  /**
   * gatewayEndpoint is the backend endpoint for the Authzed gateway. Must include
   * http:// or https://.
   */
  gatewayEndpoint: string;

  /**
   * playgroundEndpoint is the endpoint of the playground. Must include
   * http:// or https://.
   */
  playgroundEndpoint: string;

  /**
   * apiEndpoint is the endpoint of the API. Must *NOT* include a prefix, but must include
   * a port.
   */
  apiEndpoint: string;

  /**
   * promEndpoint is the endpoint of the Prometheus proxy. Must include
   * http:// or https://. Must end in /.
   */
  promEndpoint: string;

  /**
   * relayEndpoint is the endpoint for relaying messages across all Authzed frontends.
   */
  relayEndpoint: string;

  /**
   * inactiveDevelopmentTenantExpiration is the expiration (if any) in ISO-8601 duration format
   * after which a development tenant is considered inactive enough to be GCed.
   */
  inactiveDevelopmentTenantExpiration: string;
}

/**
 * AmplitudeConfig is the configuration for amplitude.
 */
interface AmplitudeConfig {
  /**
   * apiKey is the API key for an Amplitude project
   */
  apiKey: string;
}

/**
 * SentryConfig is the configuration for Sentry.
 */
interface SentryConfig {
  /**
   * dsn is the public DSN for Sentry.
   */
  dsn: string;
}

/**
 * CalendlyConfig is the configuration for Calendly.
 */
interface CalendlyConfig {
  /**
   * url is the url to display for making Calendly appointments.
   */
  url: string;
}

/**
 * DiscordConfig is the configuration for Discord.
 */
interface DiscordConfig {
  /**
   * inviteUrl is the full URL for being invited into the Discord server.
   */
  inviteUrl: string;
}

/**
 * SegmentConfig is the configuration for Segment.
 */
interface SegmentConfig {
  /**
   * writeKey is the API key for Segment.
   */
  writeKey: string;
}

interface ApplicationConfig {
  authentication: AllowedAuthenticationTypes;

  stripe: StripeConfig;
  ga: GoogleAnalyticsConfig;
  statuspage: StatusPageConfig;
  amplitude: AmplitudeConfig;
  authzed: AuthZedConfig;
  sentry: SentryConfig;
  calendly: CalendlyConfig;
  discord: DiscordConfig;
  segment: SegmentConfig;

  auth0: Auth0Config;
  oidc: OIDCConfig;
}

/**
 * AppConfig returns the ApplicationConfig.
 */
export default function AppConfig(): ApplicationConfig {
  let typed: ApplicationConfig = {
    authentication: config.authentication as AllowedAuthenticationTypes,
    auth0: config.auth0,
    oidc: config.oidc,
    authzed: config.authzed,
    stripe: config.stripe,
    ga: config.ga,
    amplitude: config.amplitude,
    statuspage: config.statuspage,
    sentry: config.sentry,
    calendly: config.calendly,
    discord: config.discord,
    segment: config.segment,
  };

  // Environment variable overrides.
  if (window._env_) {
    if (window._env_.AUTHENTICATION_ENGINE) {
      typed.authentication = window._env_
        .AUTHENTICATION_ENGINE as AllowedAuthenticationTypes;
    }

    if (window._env_.AUTH0_CLIENT_ID) {
      typed.auth0.clientID = window._env_.AUTH0_CLIENT_ID;
    }

    if (window._env_.AUTH0_CONNECTION_NAME) {
      typed.auth0.connection = window._env_.AUTH0_CONNECTION_NAME;
    }

    if (window._env_.OIDC_URL_PREFIX) {
      typed.oidc.urlPrefix = window._env_.OIDC_URL_PREFIX;
    }

    if (window._env_.AUTHZED_BACKEND_ENDPOINT) {
      typed.authzed.endpoint = window._env_.AUTHZED_BACKEND_ENDPOINT;
    }

    if (window._env_.AUTHZED_GATEWAY_ENDPOINT) {
      typed.authzed.gatewayEndpoint = window._env_.AUTHZED_GATEWAY_ENDPOINT;
    }

    if (window._env_.AUTHZED_API_ENDPOINT) {
      typed.authzed.apiEndpoint = window._env_.AUTHZED_API_ENDPOINT;
    }

    if (window._env_.AUTHZED_PROMETHEUS_ENDPOINT) {
      typed.authzed.promEndpoint = window._env_.AUTHZED_PROMETHEUS_ENDPOINT;
    }

    if (window._env_.AUTHZED_PLAYGROUND_ENDPOINT) {
      typed.authzed.playgroundEndpoint =
        window._env_.AUTHZED_PLAYGROUND_ENDPOINT;
    }

    if (window._env_.AUTHZED_RELAY_ENDPOINT) {
      typed.authzed.relayEndpoint = window._env_.AUTHZED_RELAY_ENDPOINT;
    }

    if (window._env_.AUTHZED_INACTIVE_DEVELOPMENT_TENANT_EXPIRATION) {
      typed.authzed.inactiveDevelopmentTenantExpiration =
        window._env_.AUTHZED_INACTIVE_DEVELOPMENT_TENANT_EXPIRATION;
    }

    if (window._env_.STRIPE_PUBLISHABLE_KEY) {
      typed.stripe.stripeKey = window._env_.STRIPE_PUBLISHABLE_KEY;
    }

    if (window._env_.FEATURE_BILLING) {
      typed.stripe.featureBilling =
        window._env_.FEATURE_BILLING.toLowerCase() === 'true' ||
        window._env_.FEATURE_BILLING.toLowerCase() === '1';
    }

    if (window._env_.GOOGLE_ANALYTICS_MEASUREMENT_ID) {
      typed.ga.measurementId = window._env_.GOOGLE_ANALYTICS_MEASUREMENT_ID;
    }

    if (window._env_.STATUSPAGE_PAGE_ID) {
      typed.statuspage.pageId = window._env_.STATUSPAGE_PAGE_ID;
    }

    if (window._env_.STATUSPAGE_COMPONENT_COMMA_SEP_IDS) {
      typed.statuspage.componentIds =
        window._env_.STATUSPAGE_COMPONENT_COMMA_SEP_IDS.split(',');
    }

    if (window._env_.AMPLITUDE_API_KEY) {
      typed.amplitude.apiKey = window._env_.AMPLITUDE_API_KEY;
    }

    if (window._env_.SENTRY_DSN) {
      typed.sentry.dsn = window._env_.SENTRY_DSN;
    }

    if (window._env_.CALENDLY_URL) {
      typed.calendly.url = window._env_.CALENDLY_URL;
    }

    if (window._env_.DISCORD_INVITE_URL) {
      typed.discord.inviteUrl = window._env_.DISCORD_INVITE_URL;
    }

    if (window._env_.SEGMENT_WRITE_KEY) {
      typed.segment.writeKey = window._env_.SEGMENT_WRITE_KEY;
    }
  }

  if (env.NODE_ENV === 'test') {
    return typed;
  }

  if (!typed.auth0.clientID && !typed.oidc.urlPrefix) {
    throw Error('Missing Auth0 Client ID or OIDC config');
  }

  if (!typed.authzed.endpoint) {
    throw Error('Missing Authzed endpoint');
  }

  if (!typed.authzed.gatewayEndpoint) {
    throw Error('Missing Authzed gateway endpoint');
  }

  if (!typed.authzed.apiEndpoint) {
    throw Error('Missing Authzed API endpoint');
  }

  if (typed.authzed.apiEndpoint.indexOf(':') < 0) {
    throw Error('Invalid Authzed API endpoint: missing port');
  }

  if (!typed.authzed.playgroundEndpoint) {
    throw Error('Missing Authzed playground endpoint');
  }

  if (!typed.authzed.promEndpoint) {
    throw Error('Missing Authzed prom/metrics endpoint');
  }

  if (!typed.authzed.relayEndpoint) {
    throw Error('Missing Authzed relay endpoint');
  }

  if (!typed.authzed.promEndpoint.endsWith('/')) {
    throw Error('Authzed prom/metrics endpoint must end in /');
  }

  if (typed.stripe.stripeKey && typed.stripe.stripeKey.indexOf('pk_') !== 0) {
    throw Error('Invalid Stripe Publishable Key');
  }

  if (typed.calendly.url && !typed.calendly.url.startsWith('https://')) {
    throw Error('Invalid Calendly URL');
  }

  if (
    typed.discord.inviteUrl &&
    !typed.discord.inviteUrl.startsWith('https://')
  ) {
    throw Error('Invalid Discord Invite URL');
  }

  return typed;
}
