import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { fileDialog } from 'file-select-dialog';
import React, { useState } from "react";


export interface FileSelectorProps {
    title: string
    accept?: string | string[] | undefined
    multiple?: boolean | undefined

    onChange?: (files: FileList | null) => void
    onSingleFileChange?: (file: File | null) => void
}

export function FileSelector(props: FileSelectorProps) {
    const [currentFilenames, setCurrentFilenames] = useState<string[] | undefined>(undefined);

    const conductSelection = () => {
        (async () => {
            const filelist = await fileDialog({ multiple: props.multiple === true ? true : false, accept: props.accept })
            if (filelist) {
                if (props.onChange) {
                    props.onChange(filelist);
                }

                if (filelist.length === 1 && props.onSingleFileChange) {
                    props.onSingleFileChange(filelist[0]);
                }

                setCurrentFilenames(Array.from(filelist).map((file: File) => file.name));
            } else {
                if (props.onChange) {
                    props.onChange(null);
                }

                if (props.onSingleFileChange) {
                    props.onSingleFileChange(null);
                }

                setCurrentFilenames(undefined);
            }
        })();
    };

    return <div>
        <Button fullWidth variant={currentFilenames ? "text" : "contained"} startIcon={<CloudUploadIcon />} onClick={conductSelection}>
            {currentFilenames ? currentFilenames.join(', ') : props.title}
        </Button>
    </div>;
}